import omit from 'lodash/omit';
import { ChangeEvent, FocusEventHandler } from 'react';
import { InputStyleTypes } from 'types/input';

import InputWrapper from './input-wrapper';

interface Props
  extends Omit<
    React.InputHTMLAttributes<HTMLTextAreaElement>,
    'prefix' | 'suffix' | 'onChange' | 'onBlur'
  > {
  fieldId?: string;
  error?: React.ReactNode;
  value: string | number;
  className?: string;
  label?: string | React.ReactNode;
  inputStyle?: 'primary' | 'secondary' | 'tertiary';
  affixStyle?: 'primary' | 'secondary' | 'tertiary';
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: FocusEventHandler<HTMLDivElement>;
  onMouseEnter?: () => void;
  isRounded?: boolean;
  isDisabled?: boolean;
  rows?: number;
  shouldDisplayAsterisk?: boolean;
  inputWrapperClassName?: string;
  inputWrapperType?: InputStyleTypes;
}

const Textarea = ({
  id,
  label,
  error,
  onMouseEnter,
  placeholder,
  onChange,
  onFocus,
  value,
  isDisabled,
  className = 'h-fit py-2',
  onBlur,
  shouldDisplayAsterisk,
  inputWrapperClassName,
  inputWrapperType,
  ...restProps
}: Props) => {
  return (
    <InputWrapper
      fieldId={id}
      label={label}
      error={error}
      onMouseEnter={onMouseEnter}
      placeholder={placeholder}
      isDisabled={isDisabled}
      className={className}
      onBlur={onBlur}
      shouldDisplayAsterisk={shouldDisplayAsterisk}
      inputWrapperClassName={inputWrapperClassName}
      inputWrapperType={inputWrapperType}
    >
      <textarea
        id={id}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        className="w-full appearance-none bg-transparent font-bold focus:outline-none focus:ring-0"
        placeholder={placeholder}
        {...omit(restProps, ['textAreaClassName'])}
      />
    </InputWrapper>
  );
};

export default Textarea;
