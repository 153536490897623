import cx from 'clsx';
import { Icons } from 'v2.api/src/common-generic';
import { EmptyStateStatus } from 'v2.api/src/common-generic/types/empty-state';

import Icon from './icon';

interface Props {
  title: string;
  description?: string;
  status: EmptyStateStatus;
  redirectionLink?: string;
  isDashboard?: boolean;
}

const cardConfiguration: {
  [cardStatus in EmptyStateStatus]: {
    icon: Icons;
    iconColor: string;
    borderColor: string;
    surfaceColor: string;
  };
} = {
  pending: {
    icon: 'empty-state-lightyear',
    iconColor: 'fill-icon-3',
    borderColor: 'border-border-14',
    surfaceColor: 'bg-surface-4',
  },
  toBeLaunched: {
    icon: 'empty-state-filled-hourglass',
    iconColor: 'fill-icon-3',
    borderColor: 'border-border-13',
    surfaceColor: 'bg-surface-8',
  },
  inProgress: {
    icon: 'empty-state-person',
    iconColor: 'fill-icon-11',
    borderColor: 'border-border-12',
    surfaceColor: 'bg-surface-14',
  },
  noResult: {
    icon: 'empty-state-information-circle',
    iconColor: 'fill-icon-6',
    borderColor: 'border-border-2',
    surfaceColor: 'bg-surface-1',
  },
};

const EmptyCardState = ({
  title,
  description,
  status,
  redirectionLink,
  isDashboard,
}: Props): React.ReactNode => {
  const { borderColor, surfaceColor, icon, iconColor } =
    cardConfiguration[status];

  return (
    <div
      className={cx(
        'my-6 flex w-full items-center rounded-lg border px-6 py-4',
        borderColor,
        isDashboard ? 'bg-surface-1' : surfaceColor,
      )}
    >
      <Icon type={icon} className={`${iconColor} h-12 w-12`} size="custom" />
      <div
        className={cx('ml-5 flex flex-col justify-items-center text-text-2', {
          'space-y-1': !!description,
        })}
      >
        <p className="font-bold">{title}</p>
        <p className="text-xs font-semibold">{description}</p>
        <p className="text-xs font-semibold">{redirectionLink}</p>
      </div>
    </div>
  );
};

export default EmptyCardState;
