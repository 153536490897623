import cx from 'clsx';
import { InputHTMLAttributes } from 'react';
import { NumberFormatValues, NumericFormat } from 'react-number-format';

import InputWrapper from './input-wrapper';

interface Props
  extends Omit<
    InputHTMLAttributes<HTMLInputElement>,
    'prefix' | 'suffix' | 'onChange'
  > {
  fieldId?: string;
  error?: React.ReactNode;
  value?: number;
  className?: string;
  label?: string | React.ReactNode;
  inputStyle?: 'primary' | 'secondary';
  affixStyle?: 'primary' | 'secondary';
  prefix?: string;
  suffix?: string;
  onChange: (values) => void;
  isRounded?: boolean;
  isDisabled?: boolean;
  onMouseEnter?: () => void;
}

const PriceInput = ({
  fieldId = '',
  error,
  value,
  onChange,
  className,
  label,
  prefix,
  suffix,
  isDisabled,
  ...restProps
}: Props): React.ReactNode => {
  const handleChange = (value: NumberFormatValues, { source }) => {
    if (source !== 'event') return;

    onChange(value);
  };

  return (
    <InputWrapper
      fieldId={fieldId}
      error={error}
      value={value}
      label={label}
      prefix={prefix}
      suffix={suffix}
      isDisabled={isDisabled}
      {...restProps}
    >
      <NumericFormat
        className={cx(
          'h-fit w-full',
          'appearance-none',
          'font-bold text-text-3',
          'bg-transparent focus:outline-none focus:ring-0',
          className,
        )}
        value={value}
        onValueChange={handleChange}
        thousandSeparator=" "
        placeholder={restProps?.placeholder}
      />
    </InputWrapper>
  );
};

export default PriceInput;
