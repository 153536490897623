import cx from 'clsx';
import InputError from 'components/input-error';
import map from 'lodash/map';
import { Option } from 'types/option';

import Radio from './radio';

export interface RadioListProps<T> {
  fieldId?: string;
  options: Array<Option<T>>;
  label?: string | React.ReactNode;
  selectedValue?: Option['value'];
  className?: string;
  error?: React.ReactNode;
  isHorizontal?: boolean;
  isDisabled?: boolean;
  isButton?: boolean;
  onChange: (selectedValue: Option<T>) => void;
  onFocus?: () => void;
  onMouseEnter?: () => void;
  individualRadioClassName?: string;
  isSquareButton?: boolean;
}

function RadioList<T>({
  fieldId = '',
  label,
  options,
  selectedValue,
  className,
  error,
  isHorizontal = false,
  isDisabled = false,
  isButton = false,
  onChange,
  onFocus,
  onMouseEnter,
  individualRadioClassName,
  isSquareButton,
}: RadioListProps<T>) {
  const handleMouseEnter = () => {
    onMouseEnter?.();
  };

  const handleChange = (selectedOption: Option<T>) => {
    onFocus?.();
    onChange(selectedOption);
  };

  const renderRadios = () =>
    map(options, (option, idx) => {
      const isChecked = selectedValue === option.value;

      return (
        <div id={fieldId} key={`${option.value}`} className={cx(className)}>
          <Radio
            key={idx}
            option={option}
            isChecked={isChecked}
            isDisabled={isDisabled || option.isDisabled}
            isError={!isButton && !!error}
            isButton={isButton}
            onChange={handleChange}
            className={individualRadioClassName}
            isSquareButton={isSquareButton}
          />
        </div>
      );
    });

  return (
    <div onMouseEnter={handleMouseEnter} onFocus={onFocus}>
      {label && <div className="mb-3 font-semibold text-text-2">{label}</div>}
      {isHorizontal ? (
        <div
          className={cx('flex', {
            'space-x-3': isSquareButton,
            'space-x-4': !isSquareButton,
          })}
        >
          {renderRadios()}
        </div>
      ) : (
        renderRadios()
      )}
      <InputError>{error}</InputError>
    </div>
  );
}

export default RadioList;
