import cx from 'clsx';
import { getInputStyles } from 'helpers/input';
import uniqueId from 'lodash/uniqueId';
import {
  ChangeEvent,
  FocusEventHandler,
  MutableRefObject,
  ClipboardEvent,
  useRef,
} from 'react';
import { InputStyleTypes } from 'types/input';
import { dateForInputFormat } from 'v2.api/src/common-generic';

import InputWrapper from './input-wrapper';

interface Props
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    'prefix' | 'suffix' | 'onChange' | 'onBlur'
  > {
  fieldId?: string;
  error?: React.ReactNode;
  value: string | number;
  className?: string;
  inputClassName?: string;
  inputWrapperClassName?: string;
  label?: string | React.ReactNode;
  affixStyle?: 'primary' | 'secondary' | 'tertiary';
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: FocusEventHandler<HTMLDivElement>;
  isRounded?: boolean;
  isDisabled?: boolean;
  inputRef?: MutableRefObject<HTMLDivElement>;
  inputWrapperType?: InputStyleTypes;
  shouldDisplayAsterisk?: boolean;
}

const Input = ({
  fieldId = uniqueId('text-input-'),
  error,
  value,
  onChange,
  label,
  prefix,
  suffix,
  isDisabled,
  inputRef,
  placeholder,
  className,
  inputWrapperType,
  inputWrapperClassName,
  onBlur,
  type,
  shouldDisplayAsterisk = false,
  ...restProps
}: Props): React.ReactNode => {
  const _inputRef = useRef(null);

  const handlePaste = (event: ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();

    const clipboardData = event.clipboardData.getData('text/plain');

    if (!clipboardData) return;

    const rawDate = clipboardData.split(/\//);

    if (rawDate.length !== 3) return;

    let formatedDate: string;

    if (type === 'datetime-local') {
      // 27-12-2024 -> 2024-27-12T00:00
      formatedDate = `${[rawDate[2], rawDate[1], rawDate[0]].join('-')}T00:00`;
    }

    if (type === 'date') {
      // 27-12-2024 -> 12-27-2024
      formatedDate = [rawDate[1], rawDate[0], rawDate[2]].join('-');
    }

    const dateInstance = new Date(formatedDate);
    // Checks if date is valid (NaN === NaN -> false)
    if (dateInstance.getTime() !== dateInstance.getTime()) return;

    // Triggers native onChange event (since our input is controlled)
    const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
      window.HTMLInputElement.prototype,
      'value',
    ).set;

    nativeInputValueSetter.call(
      _inputRef.current,
      type === 'datetime-local'
        ? formatedDate
        : dateForInputFormat(dateInstance),
    );

    const newEvent = new Event('input', { bubbles: true });

    _inputRef.current.dispatchEvent(newEvent);
  };

  return (
    <InputWrapper
      fieldId={fieldId}
      inputRef={inputRef}
      label={label}
      prefix={prefix}
      suffix={suffix}
      onFocus={restProps.onFocus}
      onBlur={onBlur}
      placeholder={placeholder}
      isDisabled={isDisabled}
      error={error}
      inputWrapperClassName={inputWrapperClassName}
      className={cx(className, {
        'h-10 w-36': type === 'date',
        'h-10 w-40': type === 'datetime-local',
      })}
      inputWrapperType={inputWrapperType}
      shouldDisplayAsterisk={shouldDisplayAsterisk}
    >
      <input
        type={type}
        onPaste={
          ['date', 'datetime-local'].includes(type) ? handlePaste : undefined
        }
        id={fieldId}
        value={value}
        ref={_inputRef}
        disabled={isDisabled}
        placeholder={placeholder}
        onChange={onChange}
        className={cx(
          getInputStyles(inputWrapperType),
          {
            'no-arrows': type === 'number',
          },
          'w-full bg-transparent text-[16px] sm:text-xs',
        )}
        {...restProps}
      />
    </InputWrapper>
  );
};

export default Input;
