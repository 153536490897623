import { $generateNodesFromDOM, $generateHtmlFromNodes } from '@lexical/html';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  $createParagraphNode,
  $createTextNode,
  $getRoot,
  $insertNodes,
} from 'lexical';
import { useEffect, useState } from 'react';

const OnChangePlugin = ({
  onChange,
  initialValue,
  additionalTextToInsert,
}): React.ReactNode => {
  const [editor] = useLexicalComposerContext();
  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    return editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => {
        if (isFirstRender) {
          setIsFirstRender(false);
          return;
        }

        const htmlString = $generateHtmlFromNodes(editor, null);

        onChange(htmlString);
      });
    });
  }, [editor, isFirstRender, onChange]);

  useEffect(() => {
    editor.update(() => {
      if (!initialValue) {
        return $createParagraphNode();
      }

      const parser = new DOMParser();
      const dom = parser.parseFromString(initialValue, 'text/html');

      const nodes = $generateNodesFromDOM(editor, dom);

      $getRoot().clear();

      try {
        $insertNodes(nodes);
      } catch {
        $insertNodes([$createParagraphNode().append(...nodes)]);
      }
    });
  }, [initialValue, editor]);

  useEffect(() => {
    editor.update(() => {
      if (!additionalTextToInsert) return;

      $insertNodes([$createTextNode(additionalTextToInsert)]);
    });
  }, [additionalTextToInsert, editor]);

  return null;
};

export default OnChangePlugin;
