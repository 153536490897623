import api from 'helpers/api';
import { FileScopeType } from 'types/scope';
import { FileType } from 'v2.api/src/common-business';
import { toQueryString } from 'v2.api/src/common-generic/query-string';
import { FileTypesEntity } from 'v2.api/src/entities/file-types.entity';

interface PreSignedURL {
  fields: Record<string, string>;
  url: string;
}

const getS3SignedURL = async (isTemporary?: boolean) => {
  const { data } = await api<PreSignedURL>({
    method: 'post',
    url: '/v2/storage',
    origin: process.env.NEXT_PUBLIC_V2_API_URL,
    body: { isTemporary },
  });

  return data;
};

const uploadPresignedFileToS3 = async (
  preSignedURL: PreSignedURL,
  file: File,
) => {
  const formData = new FormData();

  Object.keys(preSignedURL.fields).forEach((key) =>
    formData.append(key, preSignedURL.fields[key]),
  );

  formData.append('file', file);

  const { data } = await api<string>({
    method: 'post',
    url: preSignedURL.url,
    origin: '',
    body: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: '',
    },
  });

  return data;
};

export const getFileTypes = async () => {
  const { data } = await api<Array<FileType>>({
    method: 'get',
    url: '/v2/file-types',
    origin: process.env.NEXT_PUBLIC_V2_API_URL,
  });

  return data;
};

export const getFileTypesByScope = async (fileTypeScopeName: FileScopeType) => {
  const { data } = await api<FileTypesEntity>({
    method: 'get',
    url: `/v2/file-type-scopes/${fileTypeScopeName}/file-types`,
    origin: process.env.NEXT_PUBLIC_V2_API_URL,
  });

  return data;
};

export const getCompanyLogoFromClearbit = async (website: string) => {
  const { data } = await api<string>({
    method: 'get',
    url: `/v2/companies/logo${toQueryString({ website })}`,
    origin: process.env.NEXT_PUBLIC_V2_API_URL,
  });

  return data;
};

export { getS3SignedURL, uploadPresignedFileToS3 };
