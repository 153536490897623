import api from 'helpers/api';
import { GooglePlaceAutocomplete } from 'types/places';

export const getPlaces = async (searchValue: string) => {
  const {
    data: { predictions },
  } = await api<{ predictions: Array<GooglePlaceAutocomplete> }>({
    method: 'get',
    origin: process.env.NEXT_PUBLIC_V2_API_URL,
    url: `/v2/places?search=${searchValue}`,
  });

  return predictions;
};

export const getPlaceById = async (id: string): Promise<string> => {
  const {
    data: { formatted_address },
  } = await api<{ formatted_address: string }>({
    method: 'get',
    origin: process.env.NEXT_PUBLIC_V2_API_URL,
    url: `/v2/places/${id}`,
  });

  return formatted_address;
};
