/* eslint-disable max-lines */
import cx from 'clsx';
import Button from 'components/button';
import CheckboxList from 'components/checkbox-list';
import DocumentCard from 'components/document-card';
import DropZone from 'components/drop-zone';
import Icon from 'components/icon';
import Input from 'components/input';
import PriceInput from 'components/price-input';
import Select from 'components/select';
import Switch from 'components/switch';
import { getS3FileLocationFromXml } from 'helpers/files';
import { getNewFields, handleDeleteFile } from 'helpers/form';
import { getGridColumns } from 'helpers/stylesheet';
import filter from 'lodash/filter';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';
import map from 'lodash/map';
import omit from 'lodash/omit';
import uniq from 'lodash/uniq';
import uniqueId from 'lodash/uniqueId';
import { getS3SignedURL, uploadPresignedFileToS3 } from 'queries/files';
import { Fragment, ChangeEvent, KeyboardEvent, useEffect, useRef } from 'react';
import { getFieldErrors } from 'v2.api/src/common-business/helpers/operations';
import {
  CreatedFile,
  Field,
  FieldValue,
  Fields,
  Error,
} from 'v2.api/src/common-generic/types/form';
import { SelectValues } from 'v2.api/src/common-generic/types/select';

import AddressInput from './address-input';
import CheckboxTable from './checkbox-table';
import EditorWrapper from './editor-wrapper';
import RadioList from './radio-list';
import Textarea from './textarea';

export interface Props {
  fields: Fields<React.ReactNode>;
  errors?: Array<Error>;
  onChange: (
    fields: Fields<React.ReactNode>,
  ) => void | Fields<React.ReactNode> | Promise<void | Fields<React.ReactNode>>;
  onChangeErrors?: (errors: Array<Error>) => void;
  onFocus?: (fieldId: string) => void;
  onMouseEnter?: (fieldId: string, type?: string) => void;
  onFormComplete?: (isFormComplete: boolean) => void;
  onExtraAction?: (
    buttonId: string,
    fieldId: string,
    row: Fields<React.ReactNode>,
  ) => void;
  onDeleteFile?: (fileId: string) => Promise<void>;
  onClickCheckboxTableInlineAction?: (
    inlineActionId: string,
    row: unknown,
  ) => void;
  formId?: string;
  className?: string;
  shouldDisplayAsterisk?: boolean;
}

interface RenderFieldProps extends Field<React.ReactNode> {
  isFieldArray?: boolean;
  fieldArrayId?: string;
}

const Form = ({
  fields,
  errors = [],
  onChange,
  onFocus,
  onMouseEnter,
  onFormComplete,
  onExtraAction,
  onDeleteFile,
  onClickCheckboxTableInlineAction,
  onChangeErrors,
  formId,
  className,
  shouldDisplayAsterisk = false,
}: Props) => {
  const fieldsRef = useRef(fields);
  const touchedInputs = useRef<Array<string>>([]);

  useEffect(() => {
    fieldsRef.current = fields;
  }, [fields]);

  const handleValidation = (
    fields: Fields<React.ReactNode>,
    fieldId: string,
  ) => {
    touchedInputs.current = uniq([...touchedInputs.current, fieldId]);

    const newErrors = getFieldErrors(fields);

    const touchedFieldErrors = filter(newErrors, ({ fieldId: id }) =>
      touchedInputs.current.includes(id),
    );

    onChangeErrors?.(touchedFieldErrors);

    onFormComplete?.(isEmpty(newErrors));
  };

  const handleChange = (id: string, value: FieldValue) => {
    const newFields = getNewFields(fieldsRef.current, id, value);

    onChange(newFields);

    handleValidation(newFields, id);
  };

  const handleImageChange = async (id: string, avatar?: CreatedFile) => {
    let url = null;

    if (avatar) {
      const preSignedUrl = await getS3SignedURL(true);
      const xml = await uploadPresignedFileToS3(preSignedUrl, avatar);
      url = getS3FileLocationFromXml(xml);
    }

    handleChange(id, url);
  };

  const handleFocus = (id: string) => () => {
    onFocus?.(id);
  };

  const handleMouseEnter = (id: string, type?: string) => () => {
    onMouseEnter?.(id, type);
  };

  const handleSelectChange =
    (id: string) => (selectedValues: SelectValues<string, React.ReactNode>) =>
      handleChange(id, selectedValues);

  const handleChangeInput =
    (id: string) =>
    ({
      target: { value },
    }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      handleChange(id, value);
    };

  const handleChangeTextarea = (id: string, value: string) => {
    handleChange(id, value);
  };

  const handleChangeNumberInput =
    (id: string) =>
    ({
      target: { value },
    }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (!/^-?\d*\.?\d*$/.test(value)) return;

      handleChange(id, value);
    };

  const handleChangePriceInput =
    (id: string) =>
    ({ floatValue }) => {
      handleChange(id, floatValue);
    };

  const handleCheckboxChange = (id: string) => (value: Array<string>) => {
    handleChange(id, value);
  };

  const handleSwitchChange = (id: string) => (value: boolean) => {
    handleChange(id, value);
  };

  const handleInputKeyDown =
    (
      isFieldArray?: boolean,
      fieldArrayId?: string,
      rows?: Array<Fields<React.ReactNode>>,
      row?: Fields<React.ReactNode>,
    ) =>
    (event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (event.key !== 'Enter') return;

      if (!isFieldArray || last(rows)[0].rowId !== row[0].rowId) return;

      handleAddRow(fieldArrayId, uniqueId())();
      event.preventDefault();
    };

  const handleDeleteRow = (fieldId: string, rowId: string) => () => {
    const newFields = map(fields, (field) => {
      if (field.id !== fieldId) return field;

      const { rows, ...rest } = field;

      return {
        ...rest,
        rows: filter(
          rows,
          ([{ rowId: currentRowId }]) => currentRowId !== rowId,
        ),
      };
    });

    handleFocus(fieldId)();
    onChange(newFields);

    handleValidation(newFields, fieldId);
  };

  const handleAddRow = (fieldId: string, rowUniqueId: string) => () => {
    const newFields = map(fields, (field) => {
      if (field.id !== fieldId) return field;

      const { rows = [], row } = field;

      return {
        ...field,
        rows: [
          ...rows,
          map(row, (rowField) => ({
            ...rowField,
            id: `${rowField.id}-${rowUniqueId}`,
            rowId: rowUniqueId,
          })),
        ],
      };
    });

    handleFocus(fieldId)();
    onChange(newFields);

    handleValidation(newFields, fieldId);
  };

  const handleChangeRadio =
    (id: string) =>
    ({ value }) => {
      handleChange(id, value);
    };

  const handleChangeCheckbox = (fieldId, selectedValues) => {
    handleChange(fieldId, selectedValues);
    handleFocus(fieldId)();
  };

  const renderLabel = (
    label: string,
    labelClassName: string,
    isOptional = false,
  ): React.ReactNode => {
    if (!label) return;

    return (
      <div className={cx('space-x-1', labelClassName)}>
        <span>{label}</span>
        {!isOptional && shouldDisplayAsterisk && (
          <span className="text-text-7">{'*'}</span>
        )}
      </div>
    );
  };

  const renderField = ({
    isFieldArray,
    fieldArrayId,
    type,
    id,
    label: fieldLabel,
    isOptional,
    value,
    options,
    fieldProps: { labelClassName, isErasable: _, ...restFieldProps } = {},
    rows,
    row,
    checkboxTable,
    asyncCheckboxTable,
    checkboxTableEmptyState,
  }: RenderFieldProps) => {
    const error = find(errors, { fieldId: id })?.message;

    if (
      type === 'number' &&
      (typeof value === 'string' || typeof value === 'number')
    ) {
      return (
        <Input
          fieldId={id}
          value={value}
          label={renderLabel(fieldLabel, labelClassName, isOptional)}
          onChange={handleChangeNumberInput(id)}
          onKeyDown={handleInputKeyDown(isFieldArray, fieldArrayId, rows, row)}
          onFocus={handleFocus(id)}
          onMouseEnter={handleMouseEnter(id)}
          error={error}
          {...omit(restFieldProps, ['initialValue', 'inputStyle'])}
          className={cx(restFieldProps.className as string, 'h-10')}
        />
      );
    }

    if (['input', 'phone-number'].includes(type) && typeof value === 'string') {
      return (
        <Input
          fieldId={id}
          value={value}
          label={renderLabel(fieldLabel, labelClassName, isOptional)}
          type="text"
          onChange={handleChangeInput(id)}
          onKeyDown={handleInputKeyDown(isFieldArray, fieldArrayId, rows, row)}
          onFocus={handleFocus(id)}
          onMouseEnter={handleMouseEnter(id)}
          error={error}
          {...omit(restFieldProps, ['initialValue', 'inputStyle'])}
          className={cx(restFieldProps.className as string, 'h-10')}
        />
      );
    }

    if (
      type === 'price' &&
      (typeof value === 'number' || typeof value === 'undefined')
    ) {
      return (
        <PriceInput
          fieldId={id}
          value={value}
          label={renderLabel(fieldLabel, labelClassName, isOptional)}
          type="number"
          onChange={handleChangePriceInput(id)}
          onKeyDown={handleInputKeyDown(isFieldArray, fieldArrayId, rows, row)}
          onFocus={handleFocus(id)}
          onMouseEnter={handleMouseEnter(id)}
          error={error}
          {...restFieldProps}
          className={cx(restFieldProps.className as string, 'h-10')}
        />
      );
    }

    if (type === 'select') {
      return (
        <Select
          fieldId={id}
          values={map(options, ({ name, id }) => ({
            id,
            label: name,
            value: id,
            filterValue: typeof name === 'string' ? name : undefined,
          }))}
          label={renderLabel(fieldLabel, labelClassName, isOptional)}
          error={error}
          selectedValues={value as SelectValues<string, React.ReactNode>}
          onChange={handleSelectChange(String(id))}
          onFocus={handleFocus(id)}
          onMouseEnter={handleMouseEnter(id)}
          {...restFieldProps}
        />
      );
    }

    if (type === 'address') {
      return (
        <AddressInput
          fieldId={id}
          label={renderLabel(fieldLabel, labelClassName, isOptional)}
          value={value}
          onChange={handleSelectChange(id)}
          onSelect={handleSelectChange(id)}
          onFocus={handleFocus(id)}
          onMouseEnter={handleMouseEnter(id)}
          error={error}
          {...restFieldProps}
        />
      );
    }

    if (
      type === 'checkbox-table' &&
      typeof value !== 'string' &&
      typeof value !== 'boolean' &&
      typeof value !== 'number'
    ) {
      return (
        <CheckboxTable
          fieldId={id}
          values={options}
          selectedValues={value}
          onChange={(selectedValues) =>
            handleChangeCheckbox(id, selectedValues)
          }
          onClickInlineAction={onClickCheckboxTableInlineAction}
          onMouseEnter={handleMouseEnter(id)}
          {...checkboxTable}
          {...asyncCheckboxTable}
          {...checkboxTableEmptyState}
          {...restFieldProps}
        />
      );
    }

    if (
      type === 'radio' &&
      (typeof value === 'string' ||
        typeof value === 'boolean' ||
        typeof value === 'number' ||
        typeof value === 'undefined')
    ) {
      return (
        <RadioList
          fieldId={id}
          selectedValue={value}
          label={renderLabel(fieldLabel, labelClassName, isOptional)}
          options={map(options, ({ name, id }) => ({
            id: `${id}`,
            label: name,
            value: id,
            filterValue: name,
          }))}
          error={error}
          onChange={handleChangeRadio(id)}
          onFocus={handleFocus(id)}
          onMouseEnter={handleMouseEnter(id, type)}
          isHorizontal
          isButton
          {...restFieldProps}
        />
      );
    }

    if (type === 'password-input' && typeof value === 'string') {
      return (
        <Input
          fieldId={id}
          value={value}
          label={renderLabel(fieldLabel, labelClassName, isOptional)}
          type="password"
          onChange={handleChangeInput(id)}
          onFocus={handleFocus(id)}
          onMouseEnter={handleMouseEnter(id)}
          onKeyDown={handleInputKeyDown(isFieldArray, fieldArrayId, rows, row)}
          error={error}
          {...restFieldProps}
        />
      );
    }

    if (type === 'textarea' && typeof value === 'string') {
      const { ...textAreaRestFieldProps } = restFieldProps;

      return (
        <Textarea
          id={id}
          label={renderLabel(fieldLabel, labelClassName, isOptional)}
          error={error}
          onMouseEnter={handleMouseEnter(id)}
          onChange={handleChangeInput(id)}
          onFocus={handleFocus(id)}
          value={value}
          {...omit(textAreaRestFieldProps, 'initialValue')}
        />
      );
    }

    if (type === 'rich-textarea' && typeof value === 'string') {
      // In order to prefill with a value, please make sure to pass an `initialValue` in fieldProps
      return (
        <EditorWrapper
          fieldId={id}
          error={error}
          onChange={(newValue) => handleChangeTextarea(id, newValue)}
          label={renderLabel(fieldLabel, labelClassName, isOptional)}
          initialValue={value}
          wrapperClassName="shadow border border-border-3 rounded-lg overflow-auto"
          hasColorPicker={false}
          {...omit(restFieldProps, 'initialValue')}
        />
      );
    }

    if (type === 'checkbox') {
      return (
        <CheckboxList
          fieldId={id}
          onChange={handleCheckboxChange(id)}
          checkedValues={value as Array<string>}
          onMouseEnter={handleMouseEnter(id)}
          label={renderLabel(fieldLabel, labelClassName, isOptional)}
          options={map(options, ({ id, name }) => ({
            label: name,
            value: id,
          }))}
          error={error}
          isButton
          isHorizontal
          {...restFieldProps}
        />
      );
    }

    if (type === 'switch') {
      return (
        <Switch
          fieldId={id}
          onChange={handleSwitchChange(id)}
          isChecked={!!value}
          label={renderLabel(fieldLabel, labelClassName, isOptional)}
          onMouseEnter={handleMouseEnter(id)}
          {...restFieldProps}
        />
      );
    }

    if (type === 'file' && typeof value !== 'string') {
      const { isMultiple, className } = restFieldProps;

      return (
        <div id={id} onMouseEnter={handleMouseEnter(id)}>
          <label htmlFor={id}>
            {renderLabel(
              fieldLabel,
              labelClassName || 'text-xs text-text-2 font-semibold',
              isOptional,
            )}
          </label>
          <div key={id}>
            {map(value as CreatedFile[], (file, index) => {
              const {
                url,
                name,
                lastModified,
                file_id: fileId,
                privateUrl,
              } = file;

              if (url || privateUrl) {
                return (
                  <DocumentCard
                    key={url || privateUrl || index}
                    url={url}
                    privateUrl={privateUrl}
                    name={name}
                    onDeleteFile={
                      restFieldProps.isDisabled
                        ? undefined
                        : async () => {
                            await onDeleteFile?.(fileId);
                            handleDeleteFile(
                              fields,
                              id,
                              Number(index),
                              onChange,
                            );
                            handleValidation(fields, id);
                          }
                    }
                  />
                );
              }

              return (
                <DocumentCard
                  key={lastModified || index}
                  name={name}
                  url={url}
                  onDeleteFile={() => {
                    handleDeleteFile(fields, id, Number(index), onChange);
                    handleValidation(fields, id);
                  }}
                />
              );
            })}
          </div>
          {(isMultiple || (!isMultiple && isEmpty(value))) && (
            <DropZone
              label="Ajouter un document"
              onChange={(files) => handleChange(id, files)}
              error={error}
              className={cx(className as string, 'mt-3')}
              isMultiple={[true, 'true'].includes(
                isMultiple as boolean | string,
              )}
              onFocus={handleFocus(id)}
              {...restFieldProps}
            />
          )}
        </div>
      );
    }

    // case: image or avatar upload
    if (type === 'image') {
      const { className } = restFieldProps;

      return (
        <div id={id}>
          <label htmlFor={id}>
            {renderLabel(
              fieldLabel,
              labelClassName || 'text-xs text-text-2 font-semibold',
              isOptional,
            )}
          </label>
          <div key={id}>
            {value && (
              <DocumentCard
                name="image"
                url={value as string}
                onDeleteFile={() => {
                  handleImageChange(id);
                }}
              />
            )}
          </div>
          {isEmpty(value) && (
            <DropZone
              label="Ajouter une image"
              onChange={(files) => handleImageChange(id, files[0])}
              error={error}
              className={cx(className as string, 'mt-3')}
              onFocus={handleFocus(id)}
              accept={{
                'image/jpeg': ['.jpeg'],
                'image/png': ['.png'],
              }}
              {...restFieldProps}
            />
          )}
        </div>
      );
    }

    return null;
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
      }}
      id={formId}
      className={className}
    >
      {map(
        fields,
        ({
          type,
          id,
          label,
          isOptional,
          addRowLabel,
          addRowLabelPosition = 'top-right',
          columnLabels,
          value,
          row,
          rows,
          options,
          hint,
          className,
          fieldProps = {
            labelClassName: '',
          },
          extraButtons,
          checkboxTable,
          asyncCheckboxTable,
          checkboxTableEmptyState,
        }) => {
          if (['array-field', 'object-field'].includes(type)) {
            const renderAddRowLabelButton = (position: 'top' | 'bottom') => (
              <div
                className={cx('flex w-full items-center', {
                  'justify-start': addRowLabelPosition === `${position}-left`,
                  'justify-end': addRowLabelPosition === `${position}-right`,
                })}
              >
                <Button
                  type="styleless"
                  icon="plus"
                  className="my-2"
                  onClick={handleAddRow(id, uniqueId())}
                >
                  {addRowLabel}
                </Button>
              </div>
            );

            return (
              <div key={id} className={className}>
                {renderLabel(label, fieldProps.labelClassName, isOptional)}
                {['top-left', 'top-right'].includes(addRowLabelPosition) &&
                  renderAddRowLabelButton('top')}

                <div
                  className={cx('grid gap-2')}
                  style={{
                    gridTemplateColumns: getGridColumns(row, extraButtons),
                  }}
                >
                  {map(columnLabels, (columnLabel, idx) => (
                    <div key={columnLabel || idx} className="font-medium">
                      {columnLabel}
                    </div>
                  ))}
                  {map(rows, (row) => {
                    return (
                      <Fragment key={row[0].rowId}>
                        {map(row, (field) => (
                          <Fragment key={field.id}>
                            {renderField({
                              ...field,
                              isFieldArray: true,
                              fieldArrayId: id,
                              rows,
                              row,
                              checkboxTable,
                              asyncCheckboxTable,
                              checkboxTableEmptyState,
                            })}
                          </Fragment>
                        ))}
                        <Button
                          type="styleless-icon"
                          icon="plus"
                          iconClassName="rotate-45 mt-6"
                          onClick={handleDeleteRow(id, row[0].rowId)}
                        />
                        {map(
                          extraButtons,
                          ({ id: buttonId, icon, title, getIsDisabled }) => (
                            <Button
                              key={buttonId}
                              type="styleless-icon"
                              className="mt-3"
                              icon={icon}
                              title={title}
                              isDisabled={getIsDisabled && getIsDisabled(row)}
                              onClick={() => {
                                if (onExtraAction)
                                  onExtraAction(buttonId, id, row);
                              }}
                            />
                          ),
                        )}
                      </Fragment>
                    );
                  })}
                </div>
                {hint && (
                  <div className="mt-1 flex items-center space-x-1">
                    <Icon
                      type="information-circle"
                      size="xs"
                      className="fill-icon-5"
                    />
                    <p className="text-xs text-text-2">{hint}</p>
                  </div>
                )}
                {['bottom-left', 'bottom-right'].includes(
                  addRowLabelPosition,
                ) && renderAddRowLabelButton('bottom')}
              </div>
            );
          }

          return (
            <div key={id} className={className}>
              {renderField({
                type,
                id,
                label,
                isOptional,
                value,
                options,
                fieldProps,
                checkboxTable,
                asyncCheckboxTable,
                checkboxTableEmptyState,
              })}
              {hint && (
                <div className="mt-1 flex items-center space-x-1">
                  <Icon
                    type="information-circle"
                    size="xs"
                    className="fill-icon-5"
                  />
                  <p className="text-xs text-text-2">{hint}</p>
                </div>
              )}
            </div>
          );
        },
      )}
    </form>
  );
};

export default Form;
