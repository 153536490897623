import cx from 'clsx';
import Icon from 'components/icon';
import { Icons } from 'v2.api/src/common-generic';

interface Props {
  type: Icons;
  children: React.ReactNode;
  bgColor?: string;
  iconColor?: string;
  className?: string;
  defaultShadow?: boolean;
  onClick?: () => void;
}

const CardWithLeftIcon = ({
  type,
  children,
  bgColor,
  iconColor,
  className,
  defaultShadow = true,
  onClick,
}: Props): React.ReactNode => {
  return (
    <div
      className={cx(
        className,
        { shadow: defaultShadow },
        'my-2 flex w-full flex-grow truncate rounded-md bg-surface-2',
      )}
      onClick={onClick}
    >
      <div
        className={cx(
          'flex w-11 items-center justify-center rounded-l-md',
          { 'bg-label-1': !bgColor },
          bgColor,
        )}
      >
        <Icon
          type={type}
          className={cx(
            'mx-2 my-3 object-contain',
            { 'fill-icon-4': !iconColor },
            iconColor,
          )}
        />
      </div>
      {children}
    </div>
  );
};

export default CardWithLeftIcon;
