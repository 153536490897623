/* eslint-disable max-lines */
/* eslint-disable unused-imports/no-unused-vars */
import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
  $isListNode,
  ListNode,
} from '@lexical/list';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $isDecoratorBlockNode } from '@lexical/react/LexicalDecoratorBlockNode';
import {
  $createHeadingNode,
  $isHeadingNode,
  $isQuoteNode,
} from '@lexical/rich-text';
import {
  $setBlocksType,
  $isAtNodeEnd,
  $patchStyleText,
} from '@lexical/selection';
import {
  $getNearestNodeOfType,
  mergeRegister,
  $getNearestBlockElementAncestorOrThrow,
} from '@lexical/utils';
import cx from 'clsx';
import Icon from 'components/icon';
import Tooltip from 'components/tooltip';
import useIsMac from 'hooks/use-is-mac';
import {
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  REDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  FORMAT_TEXT_COMMAND,
  $getSelection,
  $isRangeSelection,
  $createParagraphNode,
  UNDO_COMMAND,
  $isTextNode,
  INDENT_CONTENT_COMMAND,
  OUTDENT_CONTENT_COMMAND,
} from 'lexical';
import map from 'lodash/map';
import { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import DropdownColorPicker from '../components/DropdownColorPicker';

const LowPriority = 1;

const supportedBlockTypes = new Set([
  'paragraph',
  'quote',
  'h1',
  'h2',
  'ul',
  'ol',
]);

const blockTypeToBlockName = {
  h1: 'Grand texte',
  h2: 'Petit texte',
  ol: 'Liste numérotée',
  paragraph: 'Normal',
  ul: 'Liste à puce',
};

const blockTypeToBlockIcon = {
  h1: 'h1',
  h2: 'h2',
  ol: 'ordered-list',
  paragraph: 'paragraph',
  ul: 'unordered-list',
};

export function Divider() {
  return <div className="divider" />;
}

function positionEditorElement(editor, rect) {
  if (rect === null) {
    editor.style.opacity = '0';
    editor.style.top = '-1000px';
    editor.style.left = '-1000px';
  } else {
    editor.style.opacity = '1';
    editor.style.top = `${rect.top + rect.height + window.scrollY + 10}px`;
    editor.style.left = `${
      rect.left + window.scrollX - editor.offsetWidth / 2 + rect.width / 2
    }px`;
  }
}

export function FloatingLinkEditor({ editor }) {
  const editorRef = useRef(null);
  const inputRef = useRef(null);
  const mouseDownRef = useRef(false);
  const [linkUrl, setLinkUrl] = useState('');
  const [isEditMode, setEditMode] = useState(false);
  const [lastSelection, setLastSelection] = useState(null);

  const updateLinkEditor = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent)) {
        setLinkUrl(parent.getURL());
      } else if ($isLinkNode(node)) {
        setLinkUrl(node.getURL());
      } else {
        setLinkUrl('');
      }
    }
    const editorElem = editorRef.current;
    const nativeSelection = window.getSelection();
    const activeElement = document.activeElement;

    if (editorElem === null) {
      return;
    }

    const rootElement = editor.getRootElement();
    if (
      selection !== null &&
      !nativeSelection.isCollapsed &&
      rootElement !== null &&
      rootElement.contains(nativeSelection.anchorNode)
    ) {
      const domRange = nativeSelection.getRangeAt(0);
      let rect;
      if (nativeSelection.anchorNode === rootElement) {
        let inner = rootElement;
        while (inner.firstElementChild != null) {
          inner = inner.firstElementChild;
        }
        rect = inner.getBoundingClientRect();
      } else {
        rect = domRange.getBoundingClientRect();
      }

      if (!mouseDownRef.current) {
        positionEditorElement(editorElem, rect);
      }
      setLastSelection(selection);
    } else if (!activeElement || activeElement.className !== 'link-input') {
      positionEditorElement(editorElem, null);
      setLastSelection(null);
      setEditMode(false);
      setLinkUrl('');
    }

    return true;
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateLinkEditor();
        });
      }),

      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateLinkEditor();
          return true;
        },
        LowPriority,
      ),
    );
  }, [editor, updateLinkEditor]);

  useEffect(() => {
    editor.getEditorState().read(() => {
      updateLinkEditor();
    });
  }, [editor, updateLinkEditor]);

  useEffect(() => {
    if (isEditMode && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditMode]);

  return (
    <div ref={editorRef} className="link-editor">
      {isEditMode ? (
        <input
          ref={inputRef}
          className="link-input"
          value={linkUrl}
          onChange={(event) => {
            setLinkUrl(event.target.value);
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault();
              if (lastSelection !== null) {
                if (linkUrl !== '') {
                  editor.dispatchCommand(TOGGLE_LINK_COMMAND, linkUrl);
                }
                setEditMode(false);
              }
            } else if (event.key === 'Escape') {
              event.preventDefault();
              setEditMode(false);
            }
          }}
        />
      ) : (
        <div className="link-input">
          <a href={linkUrl} target="_blank" rel="noopener noreferrer">
            {linkUrl}
          </a>
          <div
            className="link-edit"
            role="button"
            tabIndex={0}
            onMouseDown={(event) => event.preventDefault()}
            onClick={() => {
              setEditMode(true);
            }}
          />
        </div>
      )}
    </div>
  );
}

function getSelectedNode(selection) {
  const anchor = selection.anchor;
  const focus = selection.focus;
  const anchorNode = selection.anchor.getNode();
  const focusNode = selection.focus.getNode();
  if (anchorNode === focusNode) {
    return anchorNode;
  }
  const isBackward = selection.isBackward();
  if (isBackward) {
    return $isAtNodeEnd(focus) ? anchorNode : focusNode;
  } else {
    return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
  }
}

function BlockOptionsDropdownList({
  editor,
  blockType,
  toolbarRef,
  setShowBlockOptionsDropDown,
}) {
  const dropDownRef = useRef(null);

  useEffect(() => {
    const toolbar = toolbarRef.current;
    const dropDown = dropDownRef.current;

    if (toolbar !== null && dropDown !== null) {
      const { top, left } = toolbar.getBoundingClientRect();
      dropDown.style.top = `${top + 40}px`;
      dropDown.style.left = `${left}px`;
    }
  }, [dropDownRef, toolbarRef]);

  useEffect(() => {
    const dropDown = dropDownRef.current;
    const toolbar = toolbarRef.current;

    if (dropDown !== null && toolbar !== null) {
      const handle = (event) => {
        const target = event.target;

        if (!dropDown.contains(target) && !toolbar.contains(target)) {
          setShowBlockOptionsDropDown(false);
        }
      };
      document.addEventListener('click', handle);

      return () => {
        document.removeEventListener('click', handle);
      };
    }
  }, [dropDownRef, setShowBlockOptionsDropDown, toolbarRef]);

  const formatParagraph = () => {
    if (blockType !== 'paragraph') {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $setBlocksType(selection, () => $createParagraphNode());
        }
      });
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatLargeHeading = () => {
    if (blockType !== 'h1') {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $setBlocksType(selection, () => $createHeadingNode('h1'));
        }
      });
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatSmallHeading = () => {
    if (blockType !== 'h2') {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $setBlocksType(selection, () => $createHeadingNode('h2'));
        }
      });
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatBulletList = () => {
    if (blockType !== 'ul') {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND);
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatNumberedList = () => {
    if (blockType !== 'ol') {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND);
    }
    setShowBlockOptionsDropDown(false);
  };

  return (
    <div className="dropdown" ref={dropDownRef}>
      <button
        className={cx('item', { '!bg-surface-6': blockType === 'paragraph' })}
        onClick={formatParagraph}
      >
        <Icon type="paragraph" size="sm" className="mr-3 fill-icon-1" />
        <p className="text-left text-xs font-medium text-text-3">{'Normal'}</p>
      </button>
      <button
        className={cx('item', { '!bg-surface-6': blockType === 'h1' })}
        onClick={formatLargeHeading}
      >
        <Icon type="h1" size="sm" className="mr-3 fill-icon-1" />
        <p className="text-left text-xs font-medium text-text-3">
          {'Grand texte'}
        </p>
      </button>
      <button
        className={cx('item', { '!bg-surface-6': blockType === 'h2' })}
        onClick={formatSmallHeading}
      >
        <Icon type="h2" size="sm" className="mr-3 fill-icon-1" />
        <p className="text-left text-xs font-medium text-text-3">
          {'Petit texte'}
        </p>
      </button>
      <button
        className={cx('item', { '!bg-surface-6': blockType === 'ul' })}
        onClick={formatBulletList}
      >
        <Icon type="unordered-list" size="sm" className="mr-3 fill-icon-1" />
        <p className="text-left text-xs font-medium text-text-3">
          {'Liste à puce'}
        </p>
      </button>
      <button
        className={cx('item', { 'bg-surface-3': blockType === 'ol' })}
        onClick={formatNumberedList}
      >
        <Icon type="ordered-list" size="sm" className="mr-3 fill-icon-1" />
        <p className="text-left text-xs font-medium text-text-3">
          {'Liste numérotée'}
        </p>
      </button>
    </div>
  );
}

interface Props {
  className?: string;
  hasColorPicker?: boolean;
}

export default function ToolbarPlugin({ className, hasColorPicker }: Props) {
  const isMac = useIsMac();
  const [editor] = useLexicalComposerContext();
  const toolbarRef = useRef(null);
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [blockType, setBlockType] = useState('paragraph');
  const [selectedElementKey, setSelectedElementKey] = useState(null);
  const [showBlockOptionsDropDown, setShowBlockOptionsDropDown] =
    useState(false);
  const [isLink, setIsLink] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);

  const metaKeyLabel = isMac ? '⌘' : 'Ctrl';

  const applyStyleText = useCallback(
    (styles: Record<string, string>, skipHistoryStack?: boolean) => {
      editor.update(
        () => {
          const selection = $getSelection();
          if (selection !== null) {
            $patchStyleText(selection, styles);
          }
        },
        skipHistoryStack ? { tag: 'historic' } : {},
      );
    },
    [editor],
  );

  const onFontColorSelect = useCallback(
    (value: string, skipHistoryStack: boolean) => {
      applyStyleText({ color: value }, skipHistoryStack);
    },
    [applyStyleText],
  );

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);
      if (elementDOM !== null) {
        setSelectedElementKey(elementKey);
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();
          setBlockType(type);
        }
      }
      // Update text format
      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
      setIsUnderline(selection.hasFormat('underline'));

      // Update links
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true);
      } else {
        setIsLink(false);
      }
    }
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload, newEditor) => {
          updateToolbar();
          return false;
        },
        LowPriority,
      ),
      editor.registerCommand(
        CAN_UNDO_COMMAND,
        (payload) => {
          setCanUndo(payload);
          return false;
        },
        LowPriority,
      ),
      editor.registerCommand(
        CAN_REDO_COMMAND,
        (payload) => {
          setCanRedo(payload);
          return false;
        },
        LowPriority,
      ),
    );
  }, [editor, updateToolbar]);

  const insertLink = useCallback(() => {
    if (!isLink) {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, 'https://');
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    }
  }, [editor, isLink]);

  const clearFormatting = useCallback(() => {
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        const anchor = selection.anchor;
        const focus = selection.focus;
        const nodes = selection.getNodes();

        if (anchor.key === focus.key && anchor.offset === focus.offset) {
          return;
        }

        nodes.forEach((node, idx) => {
          // We split the first and last node by the selection
          // So that we don't format unselected text inside those nodes
          if ($isTextNode(node)) {
            // Use a separate variable to ensure TS does not lose the refinement
            let textNode = node;
            if (idx === 0 && anchor.offset !== 0) {
              textNode = textNode.splitText(anchor.offset)[1] || textNode;
            }
            if (idx === nodes.length - 1) {
              textNode = textNode.splitText(focus.offset)[0] || textNode;
            }

            if (textNode.__style !== '') {
              textNode.setStyle('');
            }
            if (textNode.__format !== 0) {
              textNode.setFormat(0);
              $getNearestBlockElementAncestorOrThrow(textNode).setFormat('');
            }
            node = textNode;
          } else if ($isHeadingNode(node) || $isQuoteNode(node)) {
            node.replace($createParagraphNode(), true);
          } else if ($isDecoratorBlockNode(node)) {
            node.setFormat('');
          }
        });
      }
    });
  }, [editor]);

  const renderTooltipLabel = (label: string, keyBinds: Array<string>) => {
    return (
      <>
        <p className="mb-1 text-center">{label}</p>
        <div className="space-x-2 text-center">
          {map(keyBinds, (keyBind, id) => (
            <span
              key={id}
              className="rounded bg-surface-2 px-1 py-0.5 text-text-3"
            >
              {keyBind}
            </span>
          ))}
        </div>
      </>
    );
  };

  return (
    <div
      className={cx(
        'toolbar space-x-2 overflow-x-auto border-b bg-surface-1 md:overflow-x-visible',
        className,
      )}
      ref={toolbarRef}
    >
      <Tooltip label={renderTooltipLabel('Annuler', [metaKeyLabel, 'Z'])}>
        <button
          disabled={!canUndo}
          onClick={() => {
            editor.dispatchCommand(UNDO_COMMAND, null);
          }}
          className="toolbar-item"
          aria-label="Undo"
        >
          <Icon
            size="sm"
            type="reload"
            className={cx('format scale-x-[-1]', {
              'fill-icon-1': canUndo,
              'fill-icon-6': !canUndo,
            })}
          />
        </button>
      </Tooltip>
      <Tooltip
        label={renderTooltipLabel('Rétablir', [metaKeyLabel, 'Shift', 'Z'])}
      >
        <button
          disabled={!canRedo}
          onClick={() => {
            editor.dispatchCommand(REDO_COMMAND, null);
          }}
          className="toolbar-item format"
          aria-label="Redo"
        >
          <Icon
            size="sm"
            type="reload"
            className={cx('format', {
              'fill-icon-1': canRedo,
              'fill-icon-6': !canRedo,
            })}
          />
        </button>
      </Tooltip>
      <Divider />
      {supportedBlockTypes.has(blockType) && (
        <>
          <Tooltip label="Mise en forme">
            <button
              className="toolbar-item block-controls"
              onClick={() =>
                setShowBlockOptionsDropDown(!showBlockOptionsDropDown)
              }
              aria-label="Formatting Options"
            >
              {/* <span className={'icon block-type ' + blockType} /> */}
              <Icon
                size="sm"
                type={blockTypeToBlockIcon[blockType]}
                className="mr-3 fill-icon-1"
              />
              <p className="mr-2 text-xs font-medium text-text-3">
                {blockTypeToBlockName[blockType]}
              </p>
              <Icon size="sm" type="arrow" className="fill-icon-1" />
            </button>
          </Tooltip>
          {showBlockOptionsDropDown &&
            createPortal(
              <BlockOptionsDropdownList
                editor={editor}
                blockType={blockType}
                toolbarRef={toolbarRef}
                setShowBlockOptionsDropDown={setShowBlockOptionsDropDown}
              />,
              document.body,
            )}
          <Divider />
        </>
      )}

      <Tooltip label={renderTooltipLabel('Gras', [metaKeyLabel, 'B'])}>
        <button
          onClick={() => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
          }}
          className={'toolbar-item mr-2 ' + (isBold ? 'active' : '')}
          aria-label="Format Bold"
        >
          <Icon size="sm" type="bold-text" className="fill-icon-1" />
        </button>
      </Tooltip>
      <Tooltip label={renderTooltipLabel('Italique', [metaKeyLabel, 'I'])}>
        <button
          onClick={() => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
          }}
          className={'toolbar-item mr-2 ' + (isItalic ? 'active' : '')}
          aria-label="Format Italics"
        >
          <Icon size="sm" type="italic" className="fill-icon-1" />
        </button>
      </Tooltip>
      <Tooltip label={renderTooltipLabel('Souligner', [metaKeyLabel, 'U'])}>
        <button
          onClick={() => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');
          }}
          className={'toolbar-item mr-2 ' + (isUnderline ? 'active' : '')}
          aria-label="Format Underline"
        >
          <Icon size="sm" type="underline" className="fill-icon-1" />
        </button>
      </Tooltip>
      <Tooltip
        label={renderTooltipLabel('Insérer un lien', [metaKeyLabel, 'V'])}
      >
        <button
          onClick={insertLink}
          className={'toolbar-item mr-2 ' + (isLink ? 'active' : '')}
          aria-label="Insert Link"
        >
          <Icon size="sm" type="link" className="fill-icon-1" />
        </button>
      </Tooltip>
      {isLink &&
        createPortal(<FloatingLinkEditor editor={editor} />, document.body)}
      <Divider />

      {hasColorPicker && (
        <DropdownColorPicker
          buttonClassName="toolbar-item color-picker"
          buttonAriaLabel="Formatting text color"
          buttonIconClassName="icon font-color"
          color="#000"
          onChange={onFontColorSelect}
          title="text color"
        />
      )}

      <Tooltip label="Retirer la mise en forme">
        <button
          onClick={clearFormatting}
          className={'toolbar-item mr-2 ' + (isLink ? 'active' : '')}
          aria-label="Clear Format"
        >
          <Icon size="sm" type="trash" className="fill-icon-1" />
        </button>
      </Tooltip>

      <Tooltip label={renderTooltipLabel('Indenter', ['Tab'])}>
        <button
          onClick={() => {
            editor.dispatchCommand(INDENT_CONTENT_COMMAND, undefined);
          }}
          className="toolbar-item mr-2"
          aria-label="Indenter (Shift + Tab)"
        >
          <Icon size="sm" type="indent" className="fill-icon-1" />
        </button>
      </Tooltip>
      <Tooltip label={renderTooltipLabel('Désindenter', ['Shift', 'Tab'])}>
        <button
          onClick={() => {
            editor.dispatchCommand(OUTDENT_CONTENT_COMMAND, undefined);
          }}
          className="toolbar-item"
          aria-label="Désindenter"
        >
          <Icon size="sm" type="outdent" className="fill-icon-1" />
        </button>
      </Tooltip>
    </div>
  );
}
