import StatefulPagination from 'business/stateful-pagination';
import filter from 'lodash/filter';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import some from 'lodash/some';
import { useEffect, useState } from 'react';
import { CommonButtonProps } from 'v2.api/src/common-generic';
import { ColumnsType } from 'v2.api/src/common-generic/types/table';

import Button from './button';
import Checkbox from './checkbox-list/checkbox';
import EmptyCardState from './empty-state-card';
import Spinner from './spinner';
import Table from './table';

interface InlineAction {
  id: string;
  button: CommonButtonProps<React.ReactNode>;
}

interface Props<T> {
  fieldId?: string;
  values?: Array<T>;
  selectedValues?: Array<T>;
  pageIndexQueryName?: string;
  pageSizeQueryName?: string;
  pageSize?: number;
  pageIndex?: number;
  columns: ColumnsType<T, React.ReactNode>;
  rowKey: string;
  onChange: (selectedValues: Array<T>) => void;
  getAsyncValues?: (
    pageIndexQueryName: string,
    pageSizeQueryName: string,
    pageSize: number,
    pageIndex: number,
    nextDataSource: Array<T>,
  ) => Promise<{ dataSource: Array<T>; nextDataSource: Array<T> }>;
  // Not used here but passed in table components
  isLoading?: boolean;
  onClickRow?: (row: T) => void;
  inlineActions?: Array<InlineAction>;
  onClickInlineAction?: (actionId: string, row: T) => void;
  className?: string;
  title?: string;
  description?: string;
  onMouseEnter?: () => void;
  wrapperClassName?: string;
  onHoverRow?: (row: T) => void;
}

function CheckboxTable<T>({
  fieldId = '',
  values,
  rowKey,
  columns: initialColumns,
  getAsyncValues,
  onChange,
  inlineActions,
  onClickInlineAction,
  pageIndexQueryName,
  pageSizeQueryName,
  selectedValues: initialSelectedValues = [],
  pageSize = 10,
  pageIndex: initialPageIndex = 0,
  title,
  description,
  onMouseEnter,
  wrapperClassName,
  onClickRow,
  onHoverRow,
}: Props<T>) {
  const [asyncValues, setAsyncValues] = useState<Array<T>>();
  const [nextDataSource, setNextDataSource] = useState<Array<T>>();
  const [pageIndex, setPageIndex] = useState(initialPageIndex);
  const [selectedValues, setSelectedValues] = useState(initialSelectedValues);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setSelectedValues(initialSelectedValues);
  }, [initialSelectedValues]);

  useEffect(() => {
    const initAsyncValues = async () => {
      try {
        setIsLoading(true);

        const { dataSource, nextDataSource: asyncNextDataSource } =
          await getAsyncValues(
            pageIndexQueryName,
            pageSizeQueryName,
            pageSize,
            pageIndex,
            nextDataSource,
          );
        setAsyncValues(dataSource);
        setNextDataSource(asyncNextDataSource);
      } finally {
        setIsLoading(false);
      }
    };

    if (getAsyncValues) {
      initAsyncValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex, pageIndexQueryName, pageSize, pageSizeQueryName]);

  const dataSource = values || asyncValues || [];

  const handleChange = (row: T) => {
    if (
      isEmpty(selectedValues) ||
      !find(selectedValues, { [rowKey]: row[rowKey] })
    ) {
      setSelectedValues([...selectedValues, row]);
      onChange([...selectedValues, row]);

      return;
    }

    setSelectedValues(
      filter(selectedValues, (value) => value[rowKey] !== row[rowKey]),
    );

    onChange(filter(selectedValues, (value) => value[rowKey] !== row[rowKey]));
  };

  const handleSelectAll = (isChecked: boolean) => () => {
    if (isChecked) {
      setSelectedValues((prevValues) => {
        const checkedValues = filter<T>(prevValues, (value) =>
          some(dataSource, { [rowKey]: value[rowKey] }),
        );

        const filteredCheckedValues = filter(
          prevValues,
          (value) => !some(checkedValues, { [rowKey]: value[rowKey] }),
        );

        onChange([...filteredCheckedValues, ...dataSource]);

        return [...filteredCheckedValues, ...dataSource];
      });

      return;
    }

    setSelectedValues((preValues) => {
      const selectedDisplayedValues = [];

      for (const datum of preValues) {
        if (
          some(dataSource, {
            [rowKey]: datum[rowKey],
          })
        )
          continue;

        selectedDisplayedValues.push(datum);
      }

      onChange(selectedDisplayedValues);

      return selectedDisplayedValues;
    });
  };

  const handleClickPrevious = () => {
    setNextDataSource([]);

    setPageIndex((prev) => Number(prev) - 1);
  };

  const handleClickNext = () => {
    setPageIndex((prev) => Number(prev) + 1);
  };

  if (isLoading || !values)
    return (
      <div className="flex h-40 w-full items-center justify-center">
        <Spinner />
      </div>
    );

  if (isEmpty(dataSource) && title && !isLoading) {
    return (
      <EmptyCardState
        title={title}
        description={description}
        status="noResult"
      />
    );
  }

  let columns: ColumnsType<T, React.ReactNode> = [
    {
      title: '',
      dataIndex: 'checkbox',
      className: 'w-8 px-4',
      headerClassName: 'px-4',
      key: 'checkbox',
      renderHeader() {
        const selectedDisplayedValues = [];

        for (const selectedValue of selectedValues) {
          if (some(dataSource, { [rowKey]: selectedValue[rowKey] })) {
            selectedDisplayedValues.push(selectedValue);
          }
        }

        const isChecked = !isEmpty(dataSource)
          ? selectedDisplayedValues.length === dataSource.length
          : false;

        return (
          <Checkbox
            isChecked={isChecked}
            option={{ label: '', value: true }}
            isDisabled={false}
            className="cursor-pointer"
            onChange={handleSelectAll(!isChecked)}
          />
        );
      },
      render(row: T) {
        return (
          <Checkbox
            isChecked={some(selectedValues, {
              [rowKey]: row[rowKey],
            })}
            option={{ label: '', value: true }}
            isDisabled={false}
            className="cursor-pointer"
            onChange={() => handleChange(row)}
          />
        );
      },
    },
    ...initialColumns,
  ];

  if (inlineActions) {
    columns = [
      ...columns,
      {
        title: '',
        dataIndex: 'checkbox',
        className: 'w-16 px-4',
        key: 'checkbox',
        renderHeader() {
          return null;
        },
        render(row: T) {
          return (
            <div className="flex items-center space-x-2">
              {map(inlineActions, (inlineAction: InlineAction) => {
                return (
                  <Button
                    key={inlineAction.id}
                    {...inlineAction.button}
                    onClick={() => onClickInlineAction?.(inlineAction.id, row)}
                  />
                );
              })}
            </div>
          );
        },
      },
    ];
  }

  return (
    <div onMouseEnter={onMouseEnter} className={wrapperClassName}>
      <Table
        fieldId={fieldId}
        dataSource={dataSource}
        rowKey={rowKey}
        columns={columns}
        selectedValues={selectedValues}
        onClickRow={onClickRow}
        onHoverRow={onHoverRow}
      />
      {pageIndexQueryName && (
        <StatefulPagination
          onClickPrevious={handleClickPrevious}
          onClickNext={handleClickNext}
          nextPage={dataSource.length}
          nextIndex={Number(pageIndex)}
          isLastPage={isEmpty(nextDataSource) || dataSource.length < pageSize}
          isVisible
          className="my-2"
        />
      )}
    </div>
  );
}

export default CheckboxTable;
