/* eslint-disable max-lines */

import filter from 'lodash/filter';
import find from 'lodash/find';
import get from 'lodash/get';
import intersectionBy from 'lodash/intersectionBy';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import split from 'lodash/split';
import uniqueId from 'lodash/uniqueId';
import { Coordinates, Element, Operation } from 'v2.api/src/common-business';
import {
  getElementByCoordinates,
  getFieldValue,
  isFieldEmpty,
} from 'v2.api/src/common-business/helpers/operations';
import {
  Fields,
  Field,
  FieldValue,
  CreatedFile,
  Error,
} from 'v2.api/src/common-generic/types/form';
import { SelectValues } from 'v2.api/src/common-generic/types/select';

import { debugElementNotFoundErrorToBuilder } from './debug-builder';

const getEmptyState = (fieldRow: Fields<React.ReactNode>) => [
  reduce(
    fieldRow,
    (acc, { apiMappingField, value }) => ({
      ...acc,
      [apiMappingField]: value,
    }),
    {},
  ),
];

export const getElementByCoordinatesWithErrorModal = (
  debugContext: string,
  operation: Partial<Operation<React.ReactNode>>,
  coordinates: Partial<Coordinates>,
): Element<React.ReactNode> => {
  try {
    return getElementByCoordinates<React.ReactNode>(
      debugContext,
      operation,
      coordinates,
    );
  } catch (error) {
    debugElementNotFoundErrorToBuilder(
      error.message,
      error.debugContext,
      error.stepId,
      error.coordinates,
    );
  }
};

export const getFieldsFromApiObject = <T>(
  apiObject: T,
  fields: Fields<React.ReactNode>,
): Fields<React.ReactNode> =>
  map(fields, (field: Field<React.ReactNode>) => {
    const apiValue = get(apiObject, field.apiMappingField);
    const value = getFieldValueFromApiObject(field, apiValue);

    if (field.type === 'array-field' && typeof apiValue === 'object') {
      const values = isEmpty(apiValue) ? getEmptyState(field.row) : apiValue;

      return {
        ...field,
        rows: map(values, (row) => {
          const rowUniqueId = uniqueId('row-');

          return map(field.row, (rowField) => ({
            ...rowField,
            value: getFieldValueFromApiObject(
              rowField,
              row[rowField.apiMappingField],
            ),
            rowId: rowUniqueId,
            id: `${rowField.id}-${rowUniqueId}`,
          }));
        }),
      };
    }

    if (field.type === 'object-field' && typeof apiValue === 'object') {
      const values = isEmpty(apiValue) ? {} : apiValue;

      return {
        ...field,
        rows: map(values, (value, key) => {
          const rowUniqueId = uniqueId('row-');

          return map(field.row, (rowField) => {
            return {
              ...rowField,
              value: rowField.id === 'key' ? key : value,
              rowId: rowUniqueId,
              id: `${rowField.id}-${rowUniqueId}`,
            };
          });
        }),
      };
    }

    return {
      ...field,
      value,
    };
  });

const getApiObject = <T>(
  acc: Partial<T>,
  childKey: string,
  objectKey: string,
  value: FieldValue | Array<Partial<T>> | Partial<T>,
  shouldOmitEmptyFields?: boolean,
  isErasable?: boolean,
) => {
  if (isErasable) return { ...acc, [objectKey]: value === '' ? null : value };

  if (isFieldEmpty(value) && shouldOmitEmptyFields) {
    return acc;
  }

  if (!childKey) {
    return { ...acc, [objectKey]: value ?? null };
  }

  const objectValue = acc[objectKey] ?? {};

  return { ...acc, [objectKey]: { ...objectValue, [childKey]: value } };
};

const getApiObjectFromArrayField = <T>(
  fields: Fields<React.ReactNode>,
): Partial<T> =>
  reduce(
    fields,
    (acc, field: Field<React.ReactNode>) => {
      const [objectKey, childKey] = split(field.apiMappingField, '.');
      if (!objectKey) return acc;

      return getApiObject(
        acc,
        childKey,
        objectKey,
        getFieldValue(field, false, 'get api object from array field'),
      );
    },
    {},
  );

const getApiObjectFromObjectField = <T>(
  acc: Partial<T>,
  fields: Fields<React.ReactNode>,
) => {
  const key = (find(fields, { apiMappingField: 'key' })?.value as string) || '';
  const value = find(fields, { apiMappingField: 'value' });

  return {
    ...acc,
    [key]: getFieldValue(value, false, 'get api object from object field'),
  };
};

export const getApiObjectFromFields = <T>(
  fields: Fields<React.ReactNode>,
  shouldOmitEmptyFields = true,
): Partial<T> => {
  return reduce(
    fields,
    (acc, field: Field<React.ReactNode>) => {
      const { apiMappingField, rows, type } = field;
      const [objectKey, childKey] = split(apiMappingField, '.');

      if (!objectKey) return acc;

      if (type === 'array-field' && rows) {
        return getApiObject<T>(
          acc,
          childKey,
          objectKey,
          map(rows, getApiObjectFromArrayField),
          shouldOmitEmptyFields,
        );
      }

      if (type === 'object-field' && rows) {
        return getApiObject<T>(
          acc,
          childKey,
          objectKey,
          reduce(rows, getApiObjectFromObjectField, {}),
          shouldOmitEmptyFields,
        );
      }

      return getApiObject(
        acc,
        childKey,
        objectKey,
        getFieldValue(field, false, 'get api object from fields'),
        shouldOmitEmptyFields,
        field?.fieldProps?.isErasable,
      );
    },
    {},
  );
};

export const getApiMultiPartFromFields = <T>(
  fields: Fields<React.ReactNode>,
): FormData => {
  const formData = new FormData();

  const apiObject = getApiObjectFromFields<T>(fields);

  for (const field of fields) {
    const { type, apiMappingField, value } = field;

    if (type !== 'file') {
      const formDataEntry = apiObject[apiMappingField];
      formData.append(
        apiMappingField,
        typeof formDataEntry === 'object'
          ? JSON.stringify(formDataEntry)
          : formDataEntry,
      );
      continue;
    }

    if (isArray(value) && !isEmpty(value) && value[0] instanceof File) {
      for (const file of value) {
        formData.append(`${apiMappingField}[]`, file as CreatedFile);
      }
    }
  }

  return formData;
};

export const getNewFieldsRow = (
  fieldId: string,
  value: FieldValue,
  field: Field<React.ReactNode>,
): Field<React.ReactNode> => {
  const { id, value: prevValue, type } = field;

  if (id !== fieldId) return field;

  const newValue =
    type === 'file' &&
    typeof value !== 'number' &&
    typeof value !== 'string' &&
    typeof value !== 'boolean' &&
    typeof prevValue !== 'string' &&
    typeof prevValue !== 'number' &&
    typeof prevValue !== 'boolean'
      ? ([...(prevValue || []), ...value] as FieldValue)
      : value;

  return {
    ...field,
    value: newValue,
  };
};

export const getErrors = (
  errors: Array<Error>,
  newErrors: Array<Error>,
  targetFieldId?: string,
) => [
  ...filter(errors, ({ fieldId }) => fieldId !== targetFieldId),
  ...newErrors,
];

export const getNewFields = (
  fields: Fields<React.ReactNode>,
  fieldId: string,
  value: FieldValue,
): Fields<React.ReactNode> =>
  map(fields, (field) => {
    const { rows } = field;

    if (rows) {
      return {
        ...field,
        rows: map(rows, (row) =>
          map(row, (rowField) => getNewFieldsRow(fieldId, value, rowField)),
        ),
      };
    }

    return getNewFieldsRow(fieldId, value, field);
  });

export const handleDeleteFile = (
  fields: Fields<React.ReactNode>,
  fieldId: string,
  index: number,
  onChange: (fields: Fields<React.ReactNode>) => void,
): void => {
  const newFields = map(fields, (field) => {
    if (fieldId !== field.id) {
      return field;
    }

    if (typeof field.value === 'string') return;

    return {
      ...field,
      value: (field.value as CreatedFile[]).filter(
        (_, valueIndex) => valueIndex !== index,
      ),
    };
  });

  onChange(newFields);
};

export const getFieldValueFromApiObject = (
  field: Field<React.ReactNode>,
  apiValue: FieldValue,
): FieldValue => {
  const { type, options, asyncValue } = field;

  if (type !== 'select') return apiValue ?? '';

  if (asyncValue) return asyncValue;

  const values = field.fieldProps?.isMultiple
    ? intersectionBy(
        options,
        apiValue as SelectValues<string, React.ReactNode>,
        'id',
      )
    : filter(options, ({ id }) => id === apiValue);

  return map(
    values as Array<{
      id: string;
      name: string;
    }>,
    ({ id, name }) => ({
      id,
      label: name,
      value: id,
      filterValue: name,
    }),
  );
};

export const getEmptyFieldValue = (type: string): FieldValue => {
  if (type === 'file') return [];

  if (['price', 'address', 'checkbox-table'].includes(type)) return undefined;

  return '';
};

export const resetFormFields = (fields: Fields<React.ReactNode>) =>
  map(fields, (field) => ({ ...field, value: '' }));

export function getSelectValuesFromItems<
  U,
  T extends {
    id;
    name?: string;
    display_name?: string;
  },
>(items: Array<T>): SelectValues<U, React.ReactNode> {
  return map(items, ({ id, name, display_name }: T) => ({
    id,
    label: display_name || name,
    value: id,
    filterValue: display_name || name,
  }));
}
