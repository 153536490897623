import { bolderDocumentDropzoneAccept } from 'constants/bolder-documents';

import { TRANSFORMERS } from '@lexical/markdown';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import cx from 'clsx';
import DropZone from 'components/drop-zone';
import InputError from 'components/input-error';
import { FocusEvent, MouseEvent } from 'react';

import config from './config';
import AutoLinkPlugin from './plugins/auto-link';
import FloatingToolbarPlugin from './plugins/floating-toolbar';
import CustomLinkPlugin from './plugins/link';
import OnChangePlugin from './plugins/on-change';
import ToolbarPlugin from './plugins/toolbar';

export interface Props {
  initialValue?: string;
  placeholder?: string;
  className?: string;
  fieldId?: string;
  label?: React.ReactNode;
  toolbarClassName?: string;
  wrapperClassName?: string;
  isAutoFocused?: boolean;
  isToolbarFloating?: boolean;
  hasColorPicker?: boolean;
  error?: string;
  additionalTextToInsert?: string;
  onChange: (val: string) => void;
  onDropFiles?: (files: File[]) => Promise<void>;
  onBlur?: (event: FocusEvent<HTMLDivElement>) => void;
  onMouseEnter?: (event: MouseEvent<HTMLDivElement>) => void;
  onMouseLeave?: (event: MouseEvent<HTMLDivElement>) => void;
  placeholderClassName?: string;
}

const Editor = ({
  isToolbarFloating = false,
  isAutoFocused = true,
  initialValue,
  placeholder,
  className,
  fieldId = '',
  label,
  toolbarClassName,
  wrapperClassName,
  error,
  additionalTextToInsert,
  onChange,
  onDropFiles,
  onBlur,
  onMouseEnter,
  onMouseLeave,
  hasColorPicker = true,
  placeholderClassName,
}: Props) => {
  const editor = (
    <div
      className="editor-inner"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <RichTextPlugin
        contentEditable={
          <ContentEditable
            className={cx('editor-input', className)}
            id={fieldId}
          />
        }
        placeholder={
          <p className={cx('editor-placeholder', placeholderClassName)}>
            {placeholder || 'Écris ton message...'}
          </p>
        }
        ErrorBoundary={LexicalErrorBoundary}
      />
      <HistoryPlugin />
      {isAutoFocused && <AutoFocusPlugin />}
      <ListPlugin />
      <LinkPlugin />
      <CustomLinkPlugin />
      <AutoLinkPlugin />
      <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
      <OnChangePlugin
        onChange={onChange}
        additionalTextToInsert={additionalTextToInsert}
        initialValue={initialValue}
      />
    </div>
  );

  if (onDropFiles) {
    return (
      <LexicalComposer initialConfig={config}>
        <div className="editor-container" onBlur={onBlur}>
          {isToolbarFloating ? (
            <FloatingToolbarPlugin />
          ) : (
            <ToolbarPlugin
              className={toolbarClassName}
              hasColorPicker={hasColorPicker}
            />
          )}
          <DropZone
            isMultiple
            labelClassName="font-bold text-center text-text-3 text-lg"
            label="Drag & drop ton document ici."
            onChange={onDropFiles}
            content={editor}
            accept={bolderDocumentDropzoneAccept}
            noClick
            displayAccept
          />
        </div>
      </LexicalComposer>
    );
  }

  return (
    <>
      {label && <div className="mb-4 font-semibold text-text-2">{label}</div>}
      <div className={cx(wrapperClassName, { 'border-border-7': !!error })}>
        <LexicalComposer initialConfig={config}>
          <div className="editor-container min-w-fit" onBlur={onBlur}>
            {isToolbarFloating ? (
              <FloatingToolbarPlugin />
            ) : (
              <ToolbarPlugin
                className={toolbarClassName}
                hasColorPicker={hasColorPicker}
              />
            )}
            {editor}
          </div>
        </LexicalComposer>
      </div>
      <InputError>{error}</InputError>
    </>
  );
};

export default Editor;
