import { useState } from 'react';

import Editor, { Props as EditorProps } from './lexical';

interface Props extends EditorProps {
  initialValue?: string;
}

const EditorWrapper = ({
  initialValue,
  onChange,
  ...rest
}: Props): React.ReactNode => {
  const [initialValueState, setInitialValueState] = useState(initialValue);

  const handleChange = (value) => {
    setInitialValueState(undefined);
    onChange(value);
  };

  return (
    <Editor
      {...rest}
      onChange={handleChange}
      initialValue={initialValueState}
    />
  );
};

export default EditorWrapper;
