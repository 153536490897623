import InputError from 'components/input-error';
import filter from 'lodash/filter';
import isUndefined from 'lodash/isUndefined';
import map from 'lodash/map';
import { Option } from 'types/option';

import Checkbox from './checkbox';

export interface CheckboxListProps {
  fieldId?: string;
  options: Array<Option>;
  label?: string | React.ReactNode;
  checkedValues?: Array<Option['value']>;
  className?: string;
  error?: React.ReactNode;
  isHorizontal?: boolean;
  isDisabled?: boolean;
  isButton?: boolean;
  onChange: (checkedValues: Array<Option['value']>) => void;
  onFocus?: () => void;
  onMouseEnter?: () => void;
}

const CheckboxList = ({
  fieldId = '',
  label,
  options,
  checkedValues,
  className,
  error,
  isHorizontal = false,
  isDisabled = false,
  isButton = false,
  onChange,
  onFocus,
  onMouseEnter,
}: CheckboxListProps): React.ReactNode => {
  const handleMouseEnter = () => {
    onMouseEnter?.();
  };

  const renderCheckboxes = () =>
    map(options, (option, idx) => {
      const isChecked = checkedValues?.includes(option.value);

      return (
        <div key={`${option.value}`} className={className}>
          <Checkbox
            key={idx}
            option={option}
            isChecked={isChecked}
            isDisabled={isDisabled || option.isDisabled}
            isError={!isButton && !!error}
            isButton={isButton}
            onChange={({ value }: Option) => {
              onFocus?.();
              if (!isChecked && isUndefined(checkedValues)) onChange([value]);
              else if (!isChecked) onChange([...checkedValues, value]);
              else onChange(filter(checkedValues, (el) => el !== value));
            }}
          />
        </div>
      );
    });

  return (
    <div id={fieldId} onMouseEnter={handleMouseEnter} onFocus={onFocus}>
      {label && <div className="mb-3 text-sm text-text-2">{label}</div>}
      {isHorizontal ? (
        <div className="flex items-center space-x-8">{renderCheckboxes()}</div>
      ) : (
        renderCheckboxes()
      )}
      <InputError>{error}</InputError>
    </div>
  );
};

export default CheckboxList;
