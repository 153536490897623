import cx from 'clsx';
import Icon from 'components/icon';
import { getButtonStyle } from 'helpers/button';
import noop from 'lodash/noop';
import { Option } from 'types/option';

export interface Props<T> {
  option: Option<T>;
  isChecked?: boolean;
  onChange: (selectedOption: Option<T>) => void;
  className?: string;
  labelClassName?: string;
  isDisabled?: boolean;
  isError?: boolean;
  isButton?: boolean;
  isSquareButton?: boolean;
}

function Radio<T>({
  option,
  isChecked,
  onChange,
  labelClassName,
  className,
  isDisabled = false,
  isError = false,
  isButton = false,
  isSquareButton = false,
}: Props<T>) {
  const { label, value, id } = option as {
    label: string;
    value: T;
    id: string;
  };

  const handleClick = () => {
    if (isDisabled) return;

    onChange(option);
  };

  return (
    <div
      className={cx(
        'flex items-center gap-x-2',
        className,
        isButton && getButtonStyle(isDisabled, isChecked, isError),
        {
          'cursor-pointer': !isDisabled,
          'cursor-not-allowed': isDisabled,
          'rounded-3xl px-3 py-2 font-medium': isButton,
        },
      )}
      onClick={handleClick}
      role="button"
    >
      <input
        type="radio"
        disabled={isDisabled}
        className="hidden"
        id={label || id}
        name={value ? `${value}` : ''}
        checked={isChecked || false}
        onChange={noop}
        onClick={(e) => e.stopPropagation()}
      />
      {isSquareButton ? (
        <div
          className={cx(
            'inline-flex cursor-pointer items-center rounded p-1.5 px-2.5 font-semibold',
            { 'bg-surface-5': isChecked && isDisabled },
            { 'bg-surface-9': isChecked && !isDisabled },
            {
              'bg-surface-2': !isChecked && isDisabled,
            },
            {
              'border border-border-3 bg-surface-2 shadow':
                !isChecked && !isDisabled,
            },
          )}
        >
          <label
            htmlFor={label}
            className={cx('select-none font-bold', labelClassName, {
              'cursor-pointer': !isDisabled,
              'cursor-not-allowed': isDisabled,
              'text-text-5': isChecked,
              'text-text-2': !isChecked,
            })}
          >
            {label}
          </label>
        </div>
      ) : (
        <>
          <div>
            {isChecked ? (
              <Icon
                type="option-active"
                size="sm"
                className={cx({
                  'text-color-5': !isDisabled,
                  'text-color-2': isDisabled,
                })}
              />
            ) : (
              <div
                className={cx('h-3 w-3 rounded-full border-2', {
                  'border-border-3 shadow': !isError,
                  'border-border-7': !isDisabled && isError,
                  'bg-surface-1': isDisabled,
                })}
              ></div>
            )}
          </div>
          <label
            htmlFor={label}
            className={cx('select-none font-medium', labelClassName, {
              'cursor-pointer': !isDisabled,
              'cursor-not-allowed': isDisabled,
              'text-text-3': isChecked,
              'text-text-2': !isChecked,
            })}
          >
            {label}
          </label>
        </>
      )}
    </div>
  );
}

export default Radio;
