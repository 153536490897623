export const getButtonStyle = (
  isDisabled: boolean,
  isSelected: boolean,
  isError: boolean,
) => {
  return {
    border: !isDisabled,
    'border-border-16': !isDisabled && !isError,
    'border-border-7': !isDisabled && isError,
    'bg-surface-1': isDisabled,
    'text-text-1': isSelected,
    'text-text-2': !isSelected,
  };
};
