import { InputStyleTypes } from 'types/input';

export const getInputStyles = (type?: InputStyleTypes) => {
  const baseStyle =
    'h-full space-x-2 appearance-none border-none text-text-3 font-bold focus:outline-none focus:ring-0 placeholder:text-color-3 placeholder:font-semibold bg-transparent';

  switch (type) {
    case 'styleless':
      return `${baseStyle}`;
    default:
      return `${baseStyle} h-fit`;
  }
};
