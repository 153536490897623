import cx from 'clsx';
import Button from 'components/button';
import CardWithLeftIcon from 'components/card-with-left-icon';
import { confirm } from 'components/confirmation-modal';
import Icon from 'components/icon';
import toast from 'components/toast';
import { saveAs } from 'file-saver';
import api from 'helpers/api';
import { DropboxFileRaw } from 'v2.api/src/common-business';
import { Icons } from 'v2.api/src/common-generic';

interface Props {
  name: string;
  url?: string;
  privateUrl?: string;
  file?: File;
  onDeleteFile?: () => Promise<void> | void;
  downloadIcon?: Icons;
}

const DocumentCard = ({
  url,
  privateUrl,
  file,
  name,
  onDeleteFile,
  downloadIcon = 'magnifying-glass',
}: Props): React.ReactNode => {
  const handleClickFile = async () => {
    const {
      data: {
        link: publicUrl,
        metadata: { name },
      },
    } = await api<DropboxFileRaw>({
      url: privateUrl,
      origin: '',
      method: 'get',
    });

    saveAs(publicUrl, name);
  };

  const renderFileLink = () => {
    if (!url && !file) return null;

    return (
      <a
        href={url || URL.createObjectURL(file)}
        target="_blank"
        rel="noreferrer"
        className="ml-3"
      >
        <Icon
          className="cursor-pointer fill-icon-1"
          type={downloadIcon}
          size="sm"
        />
      </a>
    );
  };

  const renderFileButton = () => (
    <Button icon={downloadIcon} type="styleless-icon" />
  );

  return (
    <CardWithLeftIcon
      type="document"
      className={cx({ 'cursor-pointer': privateUrl })}
      onClick={privateUrl ? handleClickFile : undefined}
    >
      <div className="flex w-full items-center justify-between truncate px-3">
        <div className="inline select-none truncate font-semibold">{name}</div>
        <div className="flex w-min items-center justify-end pr-2">
          {privateUrl ? renderFileButton() : renderFileLink()}
          {onDeleteFile && (
            <Button
              className="ml-3"
              type="styleless-icon"
              icon="cross"
              onClick={() => {
                confirm({
                  title: 'Suppression du document',
                  body: 'Es-tu sûr de vouloir supprimer ce document ?',
                  okText: 'Supprimer',
                  cancelText: 'Annuler',
                  onSubmit: () => {
                    try {
                      onDeleteFile();
                      toast.success('Le document a bien été supprimé');
                    } catch {
                      toast.error(
                        'Une erreur s’est produite lors de la suppression du document',
                      );
                    }
                  },
                });
              }}
            />
          )}
        </div>
      </div>
    </CardWithLeftIcon>
  );
};

export default DocumentCard;
