import { getS3SignedURL, uploadPresignedFileToS3 } from 'queries/files';

const base64ToFile = async (
  base64: string,
  fileName: string,
): Promise<File> => {
  const res: Response = await fetch(base64);
  const blob: Blob = await res.blob();

  return new File([blob], fileName, { type: 'image/png' });
};

const getParsedXml = (xml: string): XMLDocument => {
  const parser = new DOMParser();
  const xmlDocument = parser.parseFromString(xml, 'text/xml');

  return xmlDocument;
};

const getS3FileLocationFromXml = (xml: string): string =>
  getParsedXml(xml).getElementsByTagName('Location')[0].innerHTML;

const getS3FileErrorCodeFromXml = (xml: string): string => {
  const errorCodeXmlTag = getParsedXml(xml).getElementsByTagName('Code')[0];

  if (!errorCodeXmlTag) return;

  return errorCodeXmlTag.innerHTML;
};

const uploadFileToS3 = async (file: File) => {
  const preSignedUrl = await getS3SignedURL();
  const xml = await uploadPresignedFileToS3(preSignedUrl, file);
  const url = getS3FileLocationFromXml(xml);

  return { url, fileId: preSignedUrl.fields.key };
};

export {
  base64ToFile,
  getS3FileLocationFromXml,
  getS3FileErrorCodeFromXml,
  uploadFileToS3,
};
