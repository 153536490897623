import filter from 'lodash/filter';
import map from 'lodash/map';
import { Icons } from 'v2.api/src/common-generic';
import { FieldBase } from 'v2.api/src/common-generic/types/form';

export const getGridColumns = (
  row: FieldBase<string, React.ReactNode>[],
  extraButtons: Array<{
    id: string;
    icon: Icons;
    title?: string;
    getIsDisabled: (row: FieldBase<string, React.ReactNode>[]) => boolean;
  }>,
): string => {
  const rowColumns = map(
    filter(row, ({ type }) => type !== 'hidden'),
    () => 'auto',
  );

  const extraButtonsColumns = map(extraButtons, () => 'min-content');

  return [...rowColumns, ...extraButtonsColumns, 'min-content'].join(' ');
};
