import cx from 'clsx';
import Button from 'components/button';
import dropRight from 'lodash/dropRight';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

interface Props {
  onClickPrevious: (previousPage, previousIndex) => void;
  onClickNext: (nextPage, nextIndex) => void;
  nextPage: number;
  nextIndex: number;
  isLastPage: boolean;
  isVisible: boolean;
  className?: string;
}

interface QueryParams {
  status?: string;
  startDate?: string;
  endDate?: string;
  groupId?: string;
  search?: string;
}

const StatefulPagination = ({
  onClickNext,
  onClickPrevious,
  nextPage,
  nextIndex,
  isLastPage,
  isVisible,
  className,
}: Props): React.ReactNode => {
  const { query } = useRouter();
  const [pages, setPages] = useState([nextPage]);
  const [indexes, setIndexes] = useState([nextIndex]);
  const [isFirstPage, setIsFirstPage] = useState(true);
  const { search, startDate, endDate, groupId, status }: QueryParams = query;

  useEffect(() => {
    setPages([nextPage]);
    setIndexes([nextIndex]);
    setIsFirstPage(true);
  }, [search, startDate, endDate, groupId, status]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!isVisible) return null;

  return (
    <div className={cx('flex justify-center', className)}>
      {!isFirstPage && (
        <div
          className="inline-flex cursor-pointer items-center rounded-l-md border border-border-2 bg-surface-1 px-5 py-3 text-sm font-medium hover:bg-surface-8"
          onClick={() => {
            const newPages = dropRight(pages);
            const newIndexes = dropRight(indexes);
            if (newPages.length === 1) {
              setIsFirstPage(true);
            }

            setIndexes(newIndexes);
            setPages(newPages);
            onClickPrevious(
              pages[newPages.length - 1],
              indexes[newIndexes.length - 1],
            );
          }}
        >
          <Button
            type="styleless-icon"
            icon="horizontal-arrow"
            isDisabled={isFirstPage}
          />
        </div>
      )}
      {!isLastPage && (
        <div
          className="inline-flex cursor-pointer items-center rounded-r-md border border-border-2 bg-surface-1 px-5 py-3 text-sm font-medium hover:bg-surface-8"
          onClick={() => {
            setIsFirstPage(false);
            setPages([...pages, nextPage]);
            setIndexes([...indexes, nextIndex]);
            onClickNext(nextPage, nextIndex);
          }}
        >
          <Button
            type="styleless-icon"
            icon="horizontal-arrow"
            isDisabled={isLastPage}
          />
        </div>
      )}
    </div>
  );
};

export default StatefulPagination;
