import { useEffect, useState } from 'react';

const useIsMac = () => {
  const [isMac, setIsMac] = useState(false);

  useEffect(() => {
    if (!navigator) return;

    setIsMac(navigator.userAgent.indexOf('Mac OS X') != -1);
  }, []);

  return isMac;
};

export default useIsMac;
