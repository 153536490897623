import Input from 'components/input';
import debounce from 'lodash/debounce';
import map from 'lodash/map';
import { getPlaceById, getPlaces } from 'queries/places';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FieldValue } from 'v2.api/src/common-generic/types/form';
import {
  SelectValue,
  SelectValues,
} from 'v2.api/src/common-generic/types/select';

import Dropdown from './dropdown';

interface Props {
  value: FieldValue;
  label: string | React.ReactNode;
  className?: string;
  placeholder?: string;
  inputWrapperClassName?: string;
  inputClassName?: string;
  error?: React.ReactNode;
  fieldId?: string;
  onSelect: (address: SelectValues<string, React.ReactNode>) => void;
  onChange: (value: SelectValues<string, React.ReactNode>) => void;
  onFocus?: () => void;
  onMouseEnter?: () => void;
}

const AddressInput = ({
  value = [{ value: '', id: null, label: '' }],
  label,
  onSelect,
  onChange,
  className,
  onFocus,
  onMouseEnter,
  error,
  fieldId = '',
  ...restProps
}: Props): React.ReactNode => {
  const selectButton = useRef<HTMLDivElement>();
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [noAddressSelectedError, setNoAddressSelectedError] =
    useState<string>();
  const [suggestions, setSuggestions] = useState<
    Array<SelectValue<string, React.ReactNode>>
  >([]);

  useEffect(() => {
    if (!value || !value[0]?.id) return;

    setNoAddressSelectedError(null);
  }, [value]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const performSearch = useCallback(
    debounce(async (value: string) => {
      const predictions = await getPlaces(value);

      setSuggestions(
        map(predictions, ({ place_id, description }) => {
          return {
            id: place_id,
            googlePlaceId: place_id,
            label: description,
            value: place_id,
            filterValue: description,
          };
        }),
      );
    }, 500),
    [],
  );

  const handleChange = async (event: { target: { value: string } }) => {
    const {
      target: { value },
    } = event;

    performSearch(value);

    setIsDropdownVisible(true);
    setNoAddressSelectedError(
      'Tu dois sélectionner une adresse dans la liste déroulante.',
    );
    onChange([{ id: null, label: value, value }]);
  };

  const handleSelect = async (
    address: SelectValue<string, React.ReactNode>,
  ) => {
    const formattedAddress = await getPlaceById(String(address.id));

    onSelect([
      {
        ...address,
        id: address.id,
        value: formattedAddress,
        label: formattedAddress,
      },
    ]);
  };

  const selectButtonRect = selectButton.current?.getBoundingClientRect();
  const dropdownWidth = selectButtonRect?.right - selectButtonRect?.left;

  return (
    <div onMouseEnter={onMouseEnter}>
      <Dropdown
        isMultiple={false}
        selectedValues={[]}
        isDropdownVisible={isDropdownVisible}
        values={suggestions}
        onChange={handleSelect}
        onChangeDropdownVisibility={setIsDropdownVisible}
        dropdownWidth={dropdownWidth}
        className={className}
        renderTrigger={() => (
          <Input
            fieldId={fieldId}
            {...restProps}
            label={label}
            inputRef={selectButton}
            value={value[0]?.label || ''}
            onChange={handleChange}
            onFocus={onFocus}
            error={error || noAddressSelectedError}
          />
        )}
      />
    </div>
  );
};

export default AddressInput;
