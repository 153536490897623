import cx from 'clsx';
import Icon from 'components/icon';
import { getButtonStyle } from 'helpers/button';
import { SyntheticEvent } from 'react';
import { Option } from 'types/option';

export interface Props {
  option: Option;
  isChecked?: boolean;
  onChange: (selectedOption: Option) => void;
  className?: string;
  labelClassName?: string;
  isDisabled?: boolean;
  isError?: boolean;
  isButton?: boolean;
}

const Checkbox = ({
  option,
  isChecked,
  onChange,
  labelClassName,
  className,
  isDisabled = false,
  isError = false,
  isButton = false,
}: Props): React.ReactNode => {
  const { label, id } = option as { label: string; id: string };

  const handleClick = () => {
    if (isDisabled) return;

    onChange(option);
  };

  return (
    <div
      className={cx(
        'flex items-center gap-x-2',
        className,
        isButton && getButtonStyle(isDisabled, isChecked, isError),
        {
          'cursor-pointer': !isDisabled,
          'cursor-not-allowed': isDisabled,
          'rounded-3xl px-3 py-2 font-medium': isButton,
        },
      )}
      onClick={(e: SyntheticEvent) => {
        e.stopPropagation();
        handleClick();
      }}
      role="button"
      key={label || id}
    >
      <div>
        {isChecked ? (
          <Icon
            type="checkbox-checked"
            size="custom"
            className={cx('h-3 w-3', {
              'text-color-5': !isDisabled,
              'text-color-2': isDisabled,
            })}
          />
        ) : (
          <div
            className={cx('h-3 w-3 rounded-sm border-2', {
              'border-border-3': !isError,
              'border-border-7': !isDisabled && isError,
              'bg-surface-1': isDisabled,
            })}
          />
        )}
      </div>
      <label
        htmlFor={label}
        className={cx('select-none font-medium', labelClassName, {
          'cursor-pointer': !isDisabled,
          'cursor-not-allowed': isDisabled,
          'text-text-3': isChecked,
          'text-text-2': !isChecked,
        })}
      >
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
